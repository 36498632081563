import { Link, navigate} from "gatsby"
import { useLocation } from "@reach/router";
import React, { useState, useEffect } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Container } from "react-bootstrap"
import Select from "react-select"
import { areaguideURL } from "../../lib/urls"
import "./AreaGuideListingNew.scss"
import GGFXImage from "../../modules/GGFXImage"
import usePagination from "../../hooks/usePagination"
import Pagination from "../Pagination/Pagination";
import { capitalFirst } from "../Common/utils";
import useDebounce from "../../hooks/useDebounce.js";


const AreaGuideListingNew = ({ areaGuideData, strapi_id, pageContext }) => {
  let allCommunityOption = {
    label: "Select Community",
    value: "all",
  }

  const totalItems = areaGuideData?.length;
  const [totalCount, setTotalCount] = useState(totalItems)
  const [isTyping, setIsTyping] = useState(false);


  const itemsPerPage = 12
  const pageSize = Math.ceil(totalCount/itemsPerPage)

  let querySelectedOption
    if (typeof window !== "undefined") {
      const result = new URLSearchParams(window.location.search)
      const qOption = result?.get("areaguide")
      querySelectedOption = {
        label: capitalFirst(qOption?.replace(/-/g, " ")),
        value: qOption,
      }
    }
  
    let querySearchValue;
    if(typeof window !=='undefined'){
      const query = new URLSearchParams(window.location.search);
      const searchVal = query.get('search')
      querySearchValue = {
        label: capitalFirst(searchVal?.replace(/-/g, " ")),
        value: searchVal
      }
    }


  const [search, setSearch] = useState(querySearchValue.value && querySearchValue.value !== "" ?
    querySearchValue?.value : "")

  const debounceSearch = useDebounce(search, 800)
  const [selectedArea, setSelectedArea] = useState(areaGuideData)
  const [selectedOption, setSelectedOption] = useState(
    querySelectedOption?.value && querySelectedOption?.value !=='all' ? querySelectedOption : allCommunityOption)

  let communityOption = []
  communityOption.push(allCommunityOption)

  areaGuideData.forEach(element => {
    if (element.node.title) {
      communityOption.push({
        label: element.node.title,
        value: element.node.title,
      })
    }
  })

  const location = useLocation()

  const handleChange = event => {
    setSearch(event.target.value)
    setIsTyping(true)
  }

  useEffect(() => {
    setIsTyping(false);
  }, [location.pathname]);

  useEffect(()=>{
    //checking page num
    const splitUrl = location.pathname.split('/')
    const getPagePath = splitUrl?.length>2 ? splitUrl[3] :''
    const currentPageNum = parseInt(getPagePath.replace('page-', '') || 1);
    if(debounceSearch && isTyping){
    const selectVal = selectedOption.value!=='all' ?  selectedOption?.value?.toLowerCase()?.replace(/ /g,'-') :''
    const selectQry = selectVal ? `areaguide=${selectVal}`: ''
    const searchVal = search?.toLowerCase()?.replace(/ /g,'-')
    const searchQry = searchVal ? `?search=${searchVal}` :''

    const path  =  searchQry ? `${searchQry}${selectQry?'&'+selectQry:''}` : selectQry ? `?${selectQry}`:''

    navigate(`/guides/area-guides-dubai/${path}`)
    let areaGuideList = areaGuideData

    if (selectedOption.value !== "all" && search === "") {
      areaGuideList = areaGuideData.filter(area => {
        if (area.node.title) {
          return area.node.title.includes(selectedOption.value)
        }
      })
    }

    if (selectedOption.value !== "all" && search) {
      areaGuideList = areaGuideData.filter(area => {
        if (area.node.title) {
          return area.node.title?.toLowerCase().includes(selectedOption.value?.toLowerCase()?.replace(/-/g, ' '))
        }
      })
    }
    if (search) {
      let areaGuide = areaGuideList.filter((area, index) =>
        area.node?.title
          ?.toLowerCase()
          ?.includes(search.toLowerCase())
      )
      setSelectedArea(areaGuide)
      setTotalCount(areaGuide?.length)
    } else {
      setSelectedArea(areaGuideList)
      setTotalCount(areaGuideList?.length)
    }
    }
    else if(search===""&&currentPageNum ===1){
      const selectVal = selectedOption?.value !=='all' ? selectedOption?.value?.toLowerCase()?.replace(/ /g,'-') : ''
      const selectQry = selectVal ? `areaguide=${selectVal}`: ''
      const path  = selectQry ? `?${selectQry}`:''
      navigate(`/guides/area-guides-dubai/${path}`)
    }

  },[debounceSearch])


  const handleTabChange = option => {
    setSearch("")
    setSelectedOption(option)
    const selectOpt = option.value !=='all' ? option.value?.toLowerCase()?.replace(/ /g,'-') : ''
    if(selectOpt){
      navigate(`/guides/area-guides-dubai/?areaguide=${selectOpt}`)
    }
    else{
      navigate(`/guides/area-guides-dubai/  `)
    }
    if (option.value === "all") {
      setSelectedArea(areaGuideData)
      setTotalCount(areaGuideData?.length)
      return
    }
    let filteredArea = areaGuideData.filter(area => {
      if (area.node.title) {
        return area.node.title.includes(option.value)
      }
    })

    setSelectedArea(filteredArea)
    setTotalCount(filteredArea?.length)
  }

  useEffect(()=>{
    let areaGuideList = areaGuideData
    if(search){
      areaGuideList = areaGuideList.filter((area, index) =>
      area.node?.title
        ?.toLowerCase()
        ?.includes(search?.replace(/-/g, ' ').toLowerCase())
      )
    }

    if(selectedOption.value!=='all'){
      areaGuideList = areaGuideList.filter(area => {
        if (area.node.title) {
          const textConversion = capitalFirst(selectedOption.value?.replace(/-/g, " "))
          return area.node.title.includes(textConversion)
        }
      })
    }

    setSelectedArea(areaGuideList)
    setTotalCount(areaGuideList?.length)
  },[search, selectedOption])


  useEffect(() => {
    const splitUrl = location.pathname.split('/')
    const getPagePath = splitUrl?.length>2 ? splitUrl[3] :''
    const pageFromUrl = parseInt(getPagePath.replace('page-', '') || 1);
    setCurrentPage(pageFromUrl);
  }, [location.pathname]);


  const match = location.pathname.match(/page-(\d+)/);
  const currentPageFromUrl = match ? parseInt(match[1]) : 1;
  const { currentItems, currentPage, setCurrentPage } = usePagination({
    items: selectedArea,
    itemsPerPage,
    initialPage: currentPageFromUrl,
  })
  // for pagination

  var imagename = "area-guide.tile_image.list_image"


  return (
    <div className="areaguide-listing-wrapper">
      <Container>
        <div className="areaguide-animation">
          <div className="areaguide-sorting-section">
            <div className="search-box">
              <input
                className="form-control search"
                placeholder="Search Communities"
                onChange={handleChange}
                value={search?.replace(/-/g,' ')}
              />
              <span>
                <i className="icon grey-search-icon" />
              </span>
            </div>

            <div className="select-department">
              <Select
                options={communityOption}
                placeholder={"Select Community"}
                isSearchable={false}
                className={"category-select"}
                classNamePrefix={"category-select"}
                //   hideSelectedOptions={true}
                value={selectedOption}
                onChange={handleTabChange}
              />
              <span>
                <i className="icon grey-down-arrow" />
              </span>
            </div>
          </div>
        </div>
        <div className="areaguide-listing-section">
          {search
            ? currentItems?.map((data, index) => {
               
                return (
                  <div className="areaguide-list" key={data?.node?.slug}>
                    <div className="image-section">
                      <Link to={`${areaguideURL}${data?.node?.slug}/`}>
                        <GGFXImage
                        ImageSrc={data.node?.tile_image}
                        altText={`${data.node?.title}`}
                        imagetransforms={data.node?.ggfx_results}
                        renderer="srcSet"
                        imagename={imagename}
                        strapiID={data.node?.strapi_id}
                      />
                      </Link>
                      <div className="image-overlay">
                        <Link to={`${areaguideURL}${data?.node?.slug}/`}>
                          <span>
                            <i className="icon white-up-arrow" />
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="content-section">
                      <Link to={`${areaguideURL}${data?.node?.slug}/`}>
                        <h5>{data?.node?.title}</h5>
                      </Link>
                    </div>
                  </div>
                )
              })
            : currentItems?.map((data, index) => {
                return (
                  
                  <div>
                    <div className="areaguide-list" key={data?.node?.slug}>
                      <div className="image-section">
                        <Link to={`${areaguideURL}${data?.node?.slug}/`}>
                          <GGFXImage
                          ImageSrc={data.node?.tile_image}
                          altText={`${data.node?.title}`}
                          imagetransforms={data.node?.ggfx_results}
                          renderer="srcSet"
                          imagename={imagename}
                          strapiID={data.node?.strapi_id}
                        />
                        </Link>
                        <div className="image-overlay">
                          <Link to={`${areaguideURL}${data?.node?.slug}/`}>
                            <span>
                              <i className="icon white-up-arrow" />
                            </span>
                          </Link>
                        </div>
                      </div>
                      <div className="content-section">
                        <Link to={`${areaguideURL}${data?.node?.slug}/`}>
                          <h5>{data?.node?.title}</h5>
                        </Link>
                      </div>
                    </div>
                  </div>
                )
              })}
        </div>

        <Pagination
          pageSize={pageSize}
          totalCount={totalCount}
          currentPage={currentPage}
          querySelectedOption={querySelectedOption}
          querySearchValue={querySearchValue}
        />

        
      </Container>
    </div>
  )
}

export default AreaGuideListingNew